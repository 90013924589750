import React, { createRef } from "react";
import Card from "~/components/card";
import { FormatListBlog } from "~/domain/blog";
// import { Request } from "~/http/request";
import _uniqBy from "lodash/uniqBy";
import _debouce from "lodash/debounce";
import { LatestArticles } from "./components/LatestArticles";
import qs from "qs";
import { withRouter } from "next/router";
import MyPagination from "~/components/MyPagination";
import Style from "./style.module.scss";
import classNames from "classnames";
import sviteServerInstance from "~/http/svit-client";

const PAGE_SIZE = 10;

class Blogs extends React.Component {
  contentRef = createRef();
  listRef = createRef();

  constructor(props) {
    super(props);
    const {
      router,
      router: { asPath },
    } = props;
    const queryObj = qs.parse(asPath.split("?")[1]);
    this.state = {
      pageSize: this.props.pageSize || 10,
      pageNo: this.props.pageNo || 0,
      loading: false,
      current: queryObj.current ? Number(queryObj.current) : 1,
    };
  }

  render() {
    const { blogs = [], latestBlogs = [], total, current } = this.props;
    return (
      <div className={classNames(Style.list, "mx-auto max-w-2xl lg:max-w-6xl")}>
        <div className={Style["list-content"]} ref={this.contentRef}>
          {blogs.map((blog, index) => (
            <>
              {index === 3 ? (
                <div>
                  <ins
                    className="adsbygoogle"
                    style={{
                      display: "block",
                      maxHeight: "120px"
                    }}
                    data-ad-format="fluid"
                    data-ad-layout-key="-fb+5w+4e-db+86"
                    data-ad-client="ca-pub-9877289856621223"
                    data-ad-slot="2269510157"
                  ></ins>
                </div>
              ) : null}
              <Card key={blog.id} data={blog} />
            </>
          ))}
          <div className={Style["pagination"]}>
            <MyPagination
              showQuickJumper={false}
              showSizeChanger={false}
              current={+current}
              total={total}
              pathPrefix="blogs"
            />
          </div>
        </div>
        <div className={classNames(Style["list-sidebar"], "hidden xl:block")}>
          <LatestArticles list={latestBlogs} />
        </div>
      </div>
    );
  }
}

export async function getStaticProps(context) {
  const { params: { page = 1 } = {} } = context;
  // 所有文章
  const pageNo = Math.max(+page, 0);
  const res = await sviteServerInstance.getArticleList({
    pageNo: pageNo,
    pageSize: PAGE_SIZE,
  });

  const blogs = res?.data?.data.map((dt) => FormatListBlog(dt));
  // 最新10篇文章
  const latestResponse = await sviteServerInstance.getArticleList({
    pageNo: 1,
    pageSize: 10,
  });

  const latestBlogs = latestResponse.data.data
    .map((dt) => FormatListBlog(dt))
    .map(({ id, name }) => ({ id, name }));

  return {
    props: {
      current: +page,
      blogs: blogs ?? [],
      latestBlogs: latestBlogs,
      total: res?.data?.count ?? 0,
    },
    revalidate: 60,
  };
}

export async function getStaticPaths() {
  return {
    // paths: paths,
    paths: [
      // 提前生成两页数据
      {
        params: {
          page: `1`,
        },
      },
      {
        params: {
          page: `2`,
        },
      },
      {
        params: {
          page: `3`,
        },
      },
      {
        params: {
          page: `4`,
        },
      },
      {
        params: {
          page: `5`,
        },
      },
    ],
    fallback: true, // false or 'blocking'
  };
}

export default withRouter(Blogs);
